import ajax from '../assets/uilt/ajax';
export default {
  /**查询客户的卡账户信息 */
  getCardAccount: (req) => { return ajax.get({ url: "/back/customer-business/get-card-account", param: req.param, success: req.success }) },
  /**统计当天办理业务数量 */
  countToday: (req) => { return ajax.get({ url: "/back/customer-business/count-today", param: req.param, success: req.success }) },

  /**查询客户信息 */
  getCustomerInfo: (req) => { return ajax.get({ url: "/back/customer-business/get-customer-info", param: req.param, success: req.success }) },

  /**查询客户的卡账户流水 */
  listCardFlow: (req) => { return ajax.get({ url: "/back/customer-business/list-card-flow", param: req.param, success: req.success }) },

  /**计算手续费 */
  serviceChargeCompute: (req) => { return ajax.get({ url: "/back/customer-business/serviceCharge-compute", param: req.param, success: req.success }) },

  /**查询日记记录 */
  listCustomerLog: (req) => { return ajax.get({ url: "/back/customer-business/list-customer-log", param: req.param, success: req.success }) },

  /**分页查询客户业务列表(个人、公司) */
  listPage: (req) => { return ajax.get({ url: "/back/customer-business/list-page", param: req.param, success: req.success }) },
  /**客户查询业务类型列表 */
  getBusinessList: (req) => { return ajax.get({ url: "/back/customer-business/get-business-list", param: req.param, success: req.success }) },
  /**手动发送验证码 */
  sendVerificationCode: (req) => { return ajax.get({ url: "/back/customer-business/send-verification-code", param: req.param, success: req.success }) },

  /**修改密码 */
  changePassword: (req) => { return ajax.post({ url: "/back/customer-business/change-password", param: req.param, success: req.success }) },

  /**转账(双方有账户)-客户操作 */
  transferAccounts: (req) => { return ajax.post({ url: "/back/customer-business/transfer-accounts", param: req.param, success: req.success }) },

  /**账户汇款(收款人无账户)-客户操作 */
  accountRemittance: (req) => { return ajax.post({ url: "/back/customer-business/account-remittance", param: req.param, success: req.success }) },

  /**【业务】账户汇款(收款人无账户)-客户操作【批量汇款】 */
  batchAccountRemittance: (req) => { return ajax.post({ url: "/back/customer-business/batch-account-remittance", param: req.param, success: req.success }) },

  /**【业务】转账(双方有账户)-客户操作【批量转账】 */
  batchTransferaccounts: (req) => { return ajax.post({ url: "/back/customer-business/batch-transfer-accounts", param: req.param, success: req.success }) },

  /**【业务】批量汇款、转账导入数据 */
  batchRemittanceTransferImport: (req) => { return ajax.post({ url: "/back/customer-business/batch-remittance-transfer-import", param: req.param, success: req.success }) },

  /**【业务】分页查询批量汇款、转账导入的数据 */
  pageBatchRemittanceTransferImport: (req) => { return ajax.get({ url: "/back/customer-business/page-batch-remittance-transfer-import", param: req.param, success: req.success }) },

  /**【业务】分页查询批量汇款、转账导入的数据(只统计总金额(totalAmount)、总手续费(totalServiceCharge)) */
  totalBatchImport: (req) => { return ajax.get({ url: "/back/customer-business/total-batch-import", param: req.param, success: req.success }) },

  /**【批量审核业务】批量申请审核列表，分页*/
  pageBatchApply: (req) => { return ajax.get({ url: "/back/customer-business/page-batch-apply", param: req.param, success: req.success }) },

  /**【批量审核业务】根据ID查询申请*/
  getOneBatchApply: (req) => { return ajax.get({ url: "/back/customer-business/get-one-batch-apply", param: req.param, success: req.success }) },
  
  /**【批量审核业务】根据ID查询申请*/
  checkStatus: (req) => { return ajax.post({ url: "/back/customer-business/check-status", param: req.param, success: req.success }) },

}